/* User defined data
 *
 * Input user data here.
 * Ensure each item is defined, at least as an empty string.
 */
import React from "react"
import styled, { keyframes } from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Breakpoints } from "./src/util/breakpoints"
import useSiteMetadata from "./src/hooks/use-site-metadata"
import useUserPreferences from "./src/hooks/use-user-preferences"
import GoogleMap from "./src/components/google-map"
import DisplayItemList from "./src/components/display-item-list"
import Donate from "./src/components/donate"

import GoogleCalendar from "./src/components/google-calendar"

import Title from "./src/components/title"
import SlideShow from "./src/components/slideshow"
import DynamicImage from "./src/components/dynamic-image"
import TabList from "./src/components/tab-list"
import { FaNewspaper } from "react-icons/fa";

/**********************************************************************
 * Input client website customization below
 **********************************************************************/

// Define what goes into the footer.
const footer = (<>
  <p>
    &copy; {new Date().getFullYear()}&nbsp;
    <a href="https://oakparkmuslims.org/" target="_blank" rel="noreferrer">
      Oak Park Muslims
    </a>. All rights reserved.
    Fontawesome icons are licensed under&nbsp;
    <a href="https://fontawesome.com/license" target="_blank" rel="noreferrer">
      CC-BY 4.0</a>.
  </p>
</>)

/* Tab info and settings
 * Any tabs you want.
 *
 * For special tabs and components, you'll need to adjust imports in this file
 *
 * Use DynamicImage or Thumbail for images over img
 */

/* Oak Park Muslim Community Customization
 *
 * Creating a fake tab list that can be used to navigate around a single page
 */


const Separator = styled.hr`
  border-top: 1px solid lightgray;
  width: 4em;
  margin-bottom: 3em;
`
const Paragraph = styled.p`
  @media ${Breakpoints.smallOrLarger} {
    margin-left: 175px;
    margin-right: 175px;
  }
`

const CenteredParagraph = styled.p`
  text-align: center;
`

const ShortLine = styled.p`
  margin-top: 4px;
  margin-bottom: 4px;
`

const CenteredShortLine = styled(ShortLine)`
  text-align: center;
`

const StyledLink = styled.a`
  color: #00008b;
  :link {
    text-decoration: underline;
  }
  :visited {
    text-decoration: underline;
  }
  :hover {
    text-decoration: underline;
  }
  :active {
    text-decoration: underline;
  }
`
/* Flyer
 * Props:
 *   src: image file in src/images/
 *   static: image file in static/
 *   alt: alternate text
 */
const Flyer = props => {
  return (
    <section>
      <a href={`${props.static}`}
        target="_blank"
        rel="noreferrer"
        style={{
          width: "min(90vw, 900px)",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ><DynamicImage
          src={props.src}
          alt={props.alt}
        /></a>
      <div style={{ marginBottom: "32px" }}></div>
    </section>
  )
}

const ContactInfo = props => {
  return (
    <section>
      <div style={{
        display: "grid",
        gridTemplateColumns: "auto",
        justifyContent: "center",
      }}>
        <div style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          gridColumnGap: "15px",
          justifyContent: "center",
          marginBottom: "24px",
        }}>
          <a href="https://www.facebook.com/oakparkmuslimcommunity/"
            target="_blank"
            rel="noreferrer"
          >
            <i style={{ color: "#4267b2" }} className="fab fa-facebook-square fa-3x"></i>
          </a>
          <a href="https://www.instagram.com/oakparkmuslims/"
            target="_blank"
            rel="noreferrer"
          >
            <i style={{ color: "#c13584" }} className="fab fa-instagram fa-3x"></i>
          </a>
          <a href="mailto:info@oakparkmuslims.org"
            target="_blank"
            rel="noreferrer"
          >
            <i style={{ color: "#ea7517" }} className="fab fa fa-envelope fa-3x"></i>
          </a>
        </div>
      </div>
    </section>
  )
}

//******************************************************************************
// Events Tab

const eventsTab = {
  slug: "events",
  name: "Events",
  content: (<>

    <article>
      <h2>Events</h2>

      <Flyer
        alt="Jummah Prayer Flyer"
        src="Jummah_prayer_flyer_updated.png"
        static="/Jummah_prayer_flyer_updated.png"
      />

      <Flyer
        alt="OPMC Board Meeting"
        src="Board_Meeting.jpg"
        static="/Board_Meeting.jpg"
      />

      <Flyer
        alt="Quran Halaqa General"
        src="Quran_Halaqa_General.jpg"
        static="/Quran_Halaqa_General.jpg"
      />

      {/*
        <Flyer
          alt="OPMC Town Hall"
          src="OPMC_Talk_town hall.png"
          static="/OPMC_Talk_town hall.png"
        />
      */}

      {/*
      <Flyer
        alt="Halal-Fest"
        src="Halal_Fest.jpg"
        static="https://forms.gle/F8e9L1Sv8BpizxPq8"
      />
      */}

      <Flyer
        alt="Ways To Donate"
        src="WaysToDonate.jpeg"
        static="/donate/#TabContent"
      />

      {/*
      <Flyer
        alt="Open Board Position"
        src="Open Board Position.png"
        static="/Open Board Position.png"
      />
      */}

      {/*
        <Flyer
        alt="ZZZ"
        src="ZZZ.png"
        static="/ZZZ.png"
      />

      <Flyer
        alt="ZZZ"
        src="ZZZ"
        static="link"
      />    
      */}

      {/*
      <Flyer
        alt="Upcoming Events Poster"
        src="upcoming_events_poster.png"
        static="/upcoming_events_poster.pdf"
      />
      */}

      {/* Remove separator for flyer */}
      {/*
      <Separator />
      */}

      <section>
        <h2>Calendar</h2>
        <div style={{ textAlign: "center" }}>
          <p>
            <StyledLink href="https://docs.google.com/document/d/14kHLHdj-c5IefWGyd0ZaSKl9ocPu5_exAfVK9bsulAE/edit?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >Calendar in List Form (Document)</StyledLink>
          </p>
        </div>
        <GoogleCalendar
          style={{ marginBottom: "2em" }}
          src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FChicago&amp;src=bXVzbGltc29mb2FrcGFya2lsQGdtYWlsLmNvbQ&amp;color=%233F51B5&amp;showTitle=0&amp;showNav=1&amp;showDate=1&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0"
        />
      </section>

      <Donate isCondensed={true} isVisible={false} />

      {/* Temporary section for Flyer */}

      {/*
      <Flyer
        alt="Fundraising Poster"
        src="fundraising_poster.png"
        static="/fundraising_poster.png"
      />
      */}

      {/*
      <Flyer
        alt="About the Oak Park Muslim Community"
        src="about_us.png"
        static="/about_us.png"
      />
      */}

      {/* Remove separator for flyer */}
      {/*
      <Separator />
      */}
    </article>

    <ContactInfo />

  </>),

}

//******************************************************************************
// Donate Tab

const donateTab = {
  slug: "donate",
  name: "Donate",
  content: (<>
    <Donate isCondensed={false} isVisible={true} />
    <ContactInfo />
  </>),
}

//******************************************************************************
// About Tab

const aboutTab = {
  slug: "about",
  name: "About",
  content: (<>
    <article>
      <h2>About Us</h2>

      <section>
        <Paragraph>
          We are Muslims in Oak Park and the surrounding areas
          who have come together to hold
          youth and family activities, religious study, and community gatherings
          in keeping with Islamic traditions. We are families.
          We are neighbors.
          We are Muslims from all walks of life.
          We are from Oak Park, Chicago, and its suburbs.
          We are from the South and the North,
          from the Eastern and Western hemispheres, and
          from various places in between.
          Together, we are committed to creating a
          safe and welcoming space for one another, and
          to serving the larger community.
        </Paragraph>
      </section>
      <h2>Mission Statement</h2>
      <section>
        <Paragraph>
          OPMC fundraises to create a mosque and a community center that empowers Muslims from all backgrounds to engage in meaningful worship. This includes gathering for prayer, Islamic education, and supporting charitable and social activities.
        </Paragraph>
      </section>
      <Separator />
      <section>
        <h2>Board of Directors</h2>
        <DisplayItemList items={new Map([
          ["Munirah Curtis, MD", new Map(Object.entries({
            primaryInfo: (<>
              {/* The price of a menu item or a person's title for a bio */}
              <i>President</i>
            </>), description: (<>
              {/* A description of the item */}
              Dr. Curtis has lived in Oak Park with her husband and four children since 2007.
              She was part of the original group that started the OPMC.
              She has been a board member of CAIR-Michigan,
              has been an active voice in the District 97 Special Education Parent Advisory,
              and a former President of the Muslim Student Association at the University of Michigan.
            </>), image: "munirah_curtis.jpg"
          }))],

          ["Samar Hafar, PsyD", new Map(Object.entries({
            primaryInfo: (<>
              {/* The price of a menu item or a person's title for a bio */}
              <i></i>
            </>), description: (<>
              {/* A description of the item */}
              Dr. Harfi has been a part of the Oak Park Muslim Community since its inception.
              She was involved in its early planning stages and has continued to support the local Muslim community in myriad ways.
              She is a licensed psychologist and frequently shares her knowledge via presentations and talks in  the local and global community.
            </>), image: "Samar_Hafar.jpg"
          }))],

          ["Murad Omery, MD", new Map(Object.entries({
            primaryInfo: (<>
              {/* The price of a menu item or a person's title for a bio */}
              <i>Vice President</i>
            </>)
            , image: "Murad_Omery_MD.jpg"
          }))],

          ["Tanveer Azmat, PhD", new Map(Object.entries({
            primaryInfo: (<>
              {/* The price of a menu item or a person's title for a bio */}
              <i>Secretary</i>
            </>), description: (<>
              {/* A description of the item */}
              Dr. Azmat is a Ph.D. Qur’an scholar. His area of specialty is Qur’anic exegeses (tafseer).
              He trained as an electrical engineer and worked in automotive electronics for twenty years as project manager.
              Currently, he is an adjunct professor at Wilbur Wright College in Chicago, where he teaches courses in religion and humanities.
              Dr. Azmat writes and publishes academic papers on different aspects of Qur’anic studies.
            </>), image: "Tanveer_Azmat.jpg"
          }))],

        ])} />
      </section>

      <Separator />
      <section>
        <h2>Organizational Leaders</h2>

        <CenteredParagraph>
          Babacar Mbengue, PhD -- Resident Scholar
        </CenteredParagraph>
        <CenteredParagraph>
          Murad Omery, MD -- Director of Community Relations
        </CenteredParagraph>
        <CenteredParagraph>
          Bashar Abuelaynein -- Web Developer
        </CenteredParagraph>
        <CenteredParagraph>
          Sarah Sarhan -- Graphic Design
        </CenteredParagraph>
      </section>

      <Separator />
      <section>
        <h2>Location</h2>
        <CenteredParagraph>
          Many of our events are held at the <a
            href="https://kinfolkcolab.org/"
            target="_blank"
            rel="noreferrer">
            Kinfolk Colab
          </a>.
        </CenteredParagraph>
        <div style={{ marginLeft: "24px" }}>
          <CenteredShortLine>
            <strong>Kinfolk Colab</strong>
          </CenteredShortLine>
          <CenteredShortLine>
            405 S Euclid Ave 2ND FLOOR
          </CenteredShortLine>
          <CenteredShortLine>
            Oak Park, IL 60302
          </CenteredShortLine>
        </div>
        <div style={{ margin: "auto" }}>
          <GoogleMap
            margin="16px auto auto auto"
            width="min(100%, 600px)"
            height=""
            aspectRatio="1 / 1"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.5405299978593!2d-87.7956301877994!3d41.881231065097474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e34bef85d4e6d%3A0x7bd16dd65f933fdf!2sKINFOLK%20COLAB!5e0!3m2!1sen!2sus!4v1710688418418!5m2!1sen!2sus" />
        </div>
      </section>
    </article>

    <ContactInfo />

  </>),
}

//******************************************************************************
// Contact Tab

const contactTab = {
  slug: "contact",
  name: "Contact",
  content: (<>
    <h2>Contact Us</h2>

    <ContactInfo />
  </>),
}

//******************************************************************************
// Gallery Tab
const GalleryInfo = props => {
  return (
    <section>
      <div style={{
        display: "grid",
        gridTemplateColumns: "auto",
        justifyContent: "center",
      }}>
        <div style={{
          display: "grid",
          gridTemplateColumns: "auto",
          gridColumnGap: "15px",
          justifyContent: "center",
          marginBottom: "24px",
        }}>
          <div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ez5OlgzvGmQ?si=YanY0y6_mpNAGpXr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="allowfullscreen"></iframe>
          </div>
          <div style={{ paddingLeft: '100px' }}>
            <h3 style={{ color: '#800000' }}>In the News...</h3>
            <div style={{
              width: '200px',
              borderBottom: "1px solid #800000",
              borderWidth: 'medium'
            }}>

            </div>
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <FaNewspaper />
                <a style={{ color: 'blue', padding: '10px' }} target="_blank" href='/July_2024_newsletter.pdf'>July 2024</a>
              </li>
              <li>
                <FaNewspaper />
                <a style={{ color: 'blue', padding: '10px' }} target="_blank" href='/Aug_2024_newsletter.pdf'>August 2024</a>
              </li>
              <li>
                <FaNewspaper />
                <a style={{ color: 'blue', padding: '10px' }} target="_blank" href='/Sept_2024_newsletter.pdf'>September 2024</a>
              </li>
              <li>
                <FaNewspaper />
                <a style={{ color: 'blue', padding: '10px' }} target="_blank" href='/Oct_2024_newsletter.pdf'>October 2024</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section >
  )
}

//******************************************************************************
// Gallery Tab

const galleryTab = {
  slug: "gallery",
  name: "Gallery",
  content: (<>
    <h2>Gallery</h2>

    <div>
      <GalleryInfo />
    </div>
  </>),
}

const tabs = [
  eventsTab,
  donateTab,
  aboutTab,
  contactTab,
  galleryTab
]

/*******************************************************************************
 *
 * Business Splash
 *
 * Defined here to handle a lot of customization.
 * Consider relocating to components once we have themes.
 *
 ******************************************************************************/

// Need to move the id marker up so navigation is visible
// Use only if bottom tab list present
const TabContentPlaceholder = styled.div`
  position: relative;
  top: -3em;
`

// Need to move the id marker up so navigation is visible
// Use only if bottom tab list present
const StyledBusinessIdentifiers = styled.div`
  width: 100%;
  display: inline-grid;
  box-sizing: border-box;
  grid-template-columns: ${props => props.shouldUseIconLogo ?
    'min-content auto' :
    'auto'
  };
  column-gap: 15px;
  justify-content: start;
  align-items: center;
  grid-template-areas: ${props => props.shouldUseIconLogo ?
    '"icon title" "description description"' :
    '"title" "description"'
  };

  @media ${Breakpoints.smallOrLarger} {
    padding: 15px;
    padding-top: 0;
    row-gap: 7px;
    grid-template-columns: min-content auto min-content;
    grid-template-areas:
      "icon        title"
      "icon        description";
  }
`

const TitleContainer = styled.div`
  min-width: 60vw;
  grid-area: title;
`

const IconContainer = styled.div`
  grid-area: icon;
  height: 70px;
  width: 70px;

  @media ${Breakpoints.smallOrLarger} {
    height: 120px;
    width: 120px;
    padding-right: 15px;
    margin-bottom: 8px;
  }
`

const SlideshowContainer = styled.div`
  background-color: var(--backgroundColor);
  @media ${Breakpoints.smallOrLarger} {
    margin: auto;
    max-width: 90%;
  }
  @media ${Breakpoints.largeOrLarger} {
    max-width: 80%;
  }
`

const slideLeft = keyframes`
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
`

const DescriptionContainer = styled.div`
  grid-area: description;
  font-style: italic;
  font-size: larger;
  animation: ${slideLeft} 1s ease-out;

  @media ${Breakpoints.smallOrLarger} {
    margin: 0;
    align-self: baseline;
  }
`

function BusinessIdentifiers(props) {
  const siteMetadata = useSiteMetadata()
  return (
    <StyledBusinessIdentifiers
      shouldUseIconLogo={true}
      data-qa={props["data-qa"] || "BusinessIdentifiers"}
    >

      <IconContainer data-qa={"BusinessIcon"}>
        <DynamicImage
          src="icon.png"
          alt="Business icon"
          data-qa="BusinessIcon"
          shouldShowBackground={false}
        />
      </IconContainer>

      <TitleContainer>
        {/*
        <h1>
          <DynamicImage
            src="banner_logo.png"
            alt={siteMetadata.title}
            data-qa="BusinessBanner"
            shouldShowBackground={false}
          />
        </h1>
      */}
        <Title
          text={siteMetadata.title}
          data-qa={"BusinessTitle"}
        />
      </TitleContainer>

      <DescriptionContainer data-qa={"BusinessDescription"}>
        {siteMetadata.description}
      </DescriptionContainer>

    </StyledBusinessIdentifiers>

  )
}

function TabPageSplash(props) {
  const userPreferences = useUserPreferences()
  const breakpoints = useBreakpoint()

  return (<>

    <div role={"banner"}>
      {breakpoints.smallOrLarger &&
        <div role={"navigation"}>
          <TabList
            activeTab={props.activeTab}
            tabs={tabs}
          />
        </div>
      }
      <BusinessIdentifiers />

      <SlideshowContainer style={{ display: props.activeTab != "events" ? "none" : "" }}>
        <SlideShow
          images={userPreferences.splashImages}
          data-qa={"SplashImages"}
        />
      </SlideshowContainer>
    </div>
    <div style={{ marginBottom: "10px" }}></div>

    {/* Ensure bottom tab list is visible when switching tabs
        Only use with bottom tab list
    */}
    <TabContentPlaceholder id="TabContent" />
    {!breakpoints.smallOrLarger &&
      <div role={"navigation"}>

        <TabList
          activeTab={props.activeTab}
          tabs={tabs}
        />
      </div>
    }

  </>)
}

export default function getUserContent() {
  return {
    footer,
    tabs,
    BusinessIdentifiers,
    TabPageSplash,
  }
}
